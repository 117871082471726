import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0522e002 = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _51e30688 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _1ffd02ba = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _7b90f440 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _2a4e863e = () => interopDefault(import('../pages/compare/index.vue' /* webpackChunkName: "pages/compare/index" */))
const _61ac36b6 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _00c76486 = () => interopDefault(import('../pages/reset-password-again/index.vue' /* webpackChunkName: "pages/reset-password-again/index" */))
const _4a9d5e16 = () => interopDefault(import('../pages/product/catalog/index.vue' /* webpackChunkName: "pages/product/catalog/index" */))
const _7cd68810 = () => interopDefault(import('../pages/profile/company.vue' /* webpackChunkName: "pages/profile/company" */))
const _6f1e462a = () => interopDefault(import('../pages/profile/favorites.vue' /* webpackChunkName: "pages/profile/favorites" */))
const _33f3f94e = () => interopDefault(import('../pages/profile/loyalty.vue' /* webpackChunkName: "pages/profile/loyalty" */))
const _1517f5b6 = () => interopDefault(import('../pages/profile/orders/index.vue' /* webpackChunkName: "pages/profile/orders/index" */))
const _1767a234 = () => interopDefault(import('../pages/profile/promocodes.vue' /* webpackChunkName: "pages/profile/promocodes" */))
const _321c5ede = () => interopDefault(import('../pages/profile/refunds/index.vue' /* webpackChunkName: "pages/profile/refunds/index" */))
const _25837ea4 = () => interopDefault(import('../pages/profile/reviews.vue' /* webpackChunkName: "pages/profile/reviews" */))
const _9c86050c = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _bf450d82 = () => interopDefault(import('../pages/room/catalog/index.vue' /* webpackChunkName: "pages/room/catalog/index" */))
const _4043ebb8 = () => interopDefault(import('../pages/search/not-found.vue' /* webpackChunkName: "pages/search/not-found" */))
const _3046ff86 = () => interopDefault(import('../pages/checkout/success/_order.vue' /* webpackChunkName: "pages/checkout/success/_order" */))
const _2943ff20 = () => interopDefault(import('../pages/product/catalog/_slug/index.vue' /* webpackChunkName: "pages/product/catalog/_slug/index" */))
const _6a201f8f = () => interopDefault(import('../pages/profile/orders/_order/index.vue' /* webpackChunkName: "pages/profile/orders/_order/index" */))
const _88fba7d4 = () => interopDefault(import('../pages/profile/refunds/_refund.vue' /* webpackChunkName: "pages/profile/refunds/_refund" */))
const _03d9493a = () => interopDefault(import('../pages/room/catalog/_slug/index.vue' /* webpackChunkName: "pages/room/catalog/_slug/index" */))
const _49febd8b = () => interopDefault(import('../pages/profile/orders/_order/refund.vue' /* webpackChunkName: "pages/profile/orders/_order/refund" */))
const _38e6faf7 = () => interopDefault(import('../pages/blog/_slug/index.vue' /* webpackChunkName: "pages/blog/_slug/index" */))
const _0fc32ad4 = () => interopDefault(import('../pages/catalog/_query.vue' /* webpackChunkName: "pages/catalog/_query" */))
const _1833e918 = () => interopDefault(import('../pages/contacts/_id/index.vue' /* webpackChunkName: "pages/contacts/_id/index" */))
const _97d99454 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _00028ba0 = () => interopDefault(import('../pages/room/_slug/index.vue' /* webpackChunkName: "pages/room/_slug/index" */))
const _6d42a7c9 = () => interopDefault(import('../pages/search/_query.vue' /* webpackChunkName: "pages/search/_query" */))
const _eb6bceee = () => interopDefault(import('../pages/set/_id/index.vue' /* webpackChunkName: "pages/set/_id/index" */))
const _2bce868b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2a17e543 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _0522e002,
    name: "auth___pl"
  }, {
    path: "/blog",
    component: _51e30688,
    name: "blog___pl"
  }, {
    path: "/cart",
    component: _1ffd02ba,
    name: "cart___pl"
  }, {
    path: "/checkout",
    component: _7b90f440,
    name: "checkout___pl"
  }, {
    path: "/compare",
    component: _2a4e863e,
    name: "compare___pl"
  }, {
    path: "/profile",
    component: _61ac36b6,
    name: "profile___pl"
  }, {
    path: "/reset-password-again",
    component: _00c76486,
    name: "reset-password-again___pl"
  }, {
    path: "/product/catalog",
    component: _4a9d5e16,
    name: "product-catalog___pl"
  }, {
    path: "/profile/company",
    component: _7cd68810,
    name: "profile-company___pl"
  }, {
    path: "/profile/favorites",
    component: _6f1e462a,
    name: "profile-favorites___pl"
  }, {
    path: "/profile/loyalty",
    component: _33f3f94e,
    name: "profile-loyalty___pl"
  }, {
    path: "/profile/orders",
    component: _1517f5b6,
    name: "profile-orders___pl"
  }, {
    path: "/profile/promocodes",
    component: _1767a234,
    name: "profile-promocodes___pl"
  }, {
    path: "/profile/refunds",
    component: _321c5ede,
    name: "profile-refunds___pl"
  }, {
    path: "/profile/reviews",
    component: _25837ea4,
    name: "profile-reviews___pl"
  }, {
    path: "/profile/tickets",
    component: _9c86050c,
    name: "profile-tickets___pl"
  }, {
    path: "/room/catalog",
    component: _bf450d82,
    name: "room-catalog___pl"
  }, {
    path: "/search/not-found",
    component: _4043ebb8,
    name: "search-not-found___pl"
  }, {
    path: "/checkout/success/:order?",
    component: _3046ff86,
    name: "checkout-success-order___pl"
  }, {
    path: "/product/catalog/:slug",
    component: _2943ff20,
    name: "product-catalog-slug___pl"
  }, {
    path: "/profile/orders/:order",
    component: _6a201f8f,
    name: "profile-orders-order___pl"
  }, {
    path: "/profile/refunds/:refund",
    component: _88fba7d4,
    name: "profile-refunds-refund___pl"
  }, {
    path: "/room/catalog/:slug",
    component: _03d9493a,
    name: "room-catalog-slug___pl"
  }, {
    path: "/profile/orders/:order/refund",
    component: _49febd8b,
    name: "profile-orders-order-refund___pl"
  }, {
    path: "/blog/:slug",
    component: _38e6faf7,
    name: "blog-slug___pl"
  }, {
    path: "/catalog/:query?",
    component: _0fc32ad4,
    name: "catalog-query___pl"
  }, {
    path: "/contacts/:id",
    component: _1833e918,
    name: "contacts-id___pl"
  }, {
    path: "/product/:id",
    component: _97d99454,
    name: "product-id___pl"
  }, {
    path: "/room/:slug",
    component: _00028ba0,
    name: "room-slug___pl"
  }, {
    path: "/search/:query?",
    component: _6d42a7c9,
    name: "search-query___pl"
  }, {
    path: "/set/:id",
    component: _eb6bceee,
    name: "set-id___pl"
  }, {
    path: "/",
    component: _2bce868b,
    name: "index___pl"
  }, {
    path: "/:slug",
    component: _2a17e543,
    name: "slug___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
